// fonts
import "@fontsource/nunito";
import "@fontsource/roboto";
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"


import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { KindeProvider } from "@kinde-oss/kinde-auth-react";

export const wrapRootElement = ({ element }) => {
  return (
    <KindeProvider
        clientId={process.env.GATSBY_KINDE_CLIENT_ID}
        domain={process.env.GATSBY_KINDE_DOMAIN}
        logoutUri={process.env.GATSBY_KINDE_REDIRECT_URI}
        redirectUri={process.env.GATSBY_KINDE_REDIRECT_URI}
        audience={process.env.GATSBY_KINDE_AUDIENCE}
    >
      <GoogleOAuthProvider clientId={process.env.GATSBY_GOOGLE_CLIENT_ID}>
        {element}
      </GoogleOAuthProvider>
    </KindeProvider>
  )
}
